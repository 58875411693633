import { template as template_176573220575488c8cb55fdb606da4af } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_176573220575488c8cb55fdb606da4af(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
