import { template as template_4c81f4d7d6334d90b3fbace9c20d41f6 } from "@ember/template-compiler";
const WelcomeHeader = template_4c81f4d7d6334d90b3fbace9c20d41f6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
