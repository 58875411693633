import { template as template_61f167d9b66a40d2983e9a189a9e304c } from "@ember/template-compiler";
const FKControlMenuContainer = template_61f167d9b66a40d2983e9a189a9e304c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
