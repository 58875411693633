import { template as template_ecac1d85b09b4c369bfeedd3e80f2e21 } from "@ember/template-compiler";
const FKLabel = template_ecac1d85b09b4c369bfeedd3e80f2e21(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
